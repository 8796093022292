// Draggable item
.cdk-drag {
  // Set up for drag indicator
  position: relative;

  // Animate items as they are dragged
  &:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, .2, 1);
  }

  .cdk-drag-handle {
    color: #152c9c;
    cursor: grab;
    position: absolute;
    right: var(--ts-space-inline-300);
  }

  // Container for item currently being moved
  &.cdk-drag-preview {
    /* stylelint-disable-next-line plugin/stylelint-no-indistinguishable-colors */
    --shadow:
      0 5px 5px -3px rgba(0, 0, 0, .2),
      0 8px 10px 1px rgba(0, 0, 0, .14),
      0 3px 14px 2px rgba(0, 0, 0, .12);
    background-color: var(--ts-color-light);
    box-shadow: var(--shadow);
    min-height: 50px;
    // NOTE: Since the width is dynamically set by the CDK, we zero out the padding here and fake it with left margin below.
    /* stylelint-disable-next-line declaration-no-important */
    padding: 0 !important;

    .c-checkbox {
      margin-left: var(--ts-space-inline-500);
    }
  }
}

// Container that represents the current drop location
.cdk-drag-placeholder {
  background: var(--ts-color-utility-200);
  border: 2px solid var(--ts-color-utility-500);
  // NOTE: This is the cursor the user will see when dragging an item (the drag preview has `pointer-events: none`)
  cursor: grabbing;
  transition: transform 250ms cubic-bezier(0, 0, .2, 1);
}

