@import '../layout/opposite-direction';
@import '../layout/z-index';

/**
 * Create a triangle shape using CSS
 *
 * @mixin triangle
 * @section Style Helpers
 * @param $direction
 *  Triangle direction, either `top`, `right`, `bottom` or `left`. Default: top
 * @param $color
 *  Triangle color. Default: currentcolor
 * @param $size
 *  Triangle size. Default: 1em
 * @example
 *  @include triangle;
 *  @include triangle(left, red, 2em);
 */
@mixin triangle($direction: top, $color: currentcolor, $size: 1em) {
  $perpendicular-borders: $size solid transparent;
  content: '';
  height: 0;
  position: absolute;
  width: 0;
  z-index: z(tooltip);

  @if not index(top right bottom left, $direction) {
    @error 'Direction must be either `top`, `right`, `bottom` or `left`.';
  }

  @if $direction == top or $direction == bottom {
    border-left: $perpendicular-borders;
    border-right: $perpendicular-borders;
  } @else if $direction == right or $direction == left {
    border-bottom: $perpendicular-borders;
    border-top: $perpendicular-borders;
  }

  @if $direction == top {
    bottom: 100%;
  }

  @if $direction == bottom {
    top: 100%;
  }

  border-#{opposite-direction($direction)}: ($size * 1.5) solid $color;
}
