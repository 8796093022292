@import 'lib-index';
@import 'variables';
@import 'mixins';

* {
    font-family: 'Roboto', Arial, sans-serif;
    box-sizing: border-box;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: 'Roboto', Arial, sans-serif;
}

*:focus {
    outline: 0;
}

a {
    text-decoration: none;
    cursor: pointer;
    color: #152c9c;

    &:hover {
        text-decoration: underline;
        color: $blue80;
    }
}

.pointer {
    cursor: pointer;
}

.report-divider {
    border: none;
    border-top: 1px solid $grey20;
    width: 100%;
    margin: spacing(large) 0;
}

.table-container {
    overflow-x: auto;
}

.negative {
    font-size: 13px;
    color: $red60;

    .fa-arrow-right {
        transform: rotate(45deg);
    }
}

.positive {
    font-size: 13px;
    color: $green60;

    .fa-arrow-right {
        transform: rotate(-45deg);
    }
}

.same {
    font-size: 13px;

    .fa-arrow-right {
        display: none;
    }
}

.text-capitalize {
    text-transform: capitalize;
}

.report-tab-button {
    display: inline-block;
    margin-right: 4px;
    text-decoration: none;
    font-size: 14px;
    line-height: 16px;
    padding: 8px 13px;
    color: $grey70;
    border: 1px solid transparent;
    border-radius: $main-border-radius;
    background-color: transparent;
    cursor: pointer;
    @include transition();

    .report-tab-count {
        margin: 0 5px;
        font-size: 12px;
        line-height: 16px;
        border-radius: 16px;
        background-color: $grey30;
        padding: 2px 6px;
        color: $grey70;
        font-weight: 500;
        @include transition();
    }

    &:hover, &.active {
        background-color: $blue10;
        border-color: $blue20;
        color: $blue70;

        .report-tab-count {
            background-color: $blue30;
            color: $blue70;
        }
    }

    &.active:hover {
        cursor: not-allowed;
    }
}

table {
    [class*="fa-angle-"] {
        padding: 2px 5px;
        border-radius: $main-border-radius;

        &:hover {
            background-color: $grey10;
        }
    }
}
