/**
 * Make content visually hidden
 *
 * @example
 *  @include visually-hidden;
 */
@mixin visually-hidden {
  $smallest-adjustment: 1px;
  border: 0;
  // noinspection CssInvalidPropertyValue
  clip-path: rect(0, 0, 0, 0);
  // Not all styles apply to inline elements
  display: block;
  height: $smallest-adjustment;
  margin: -$smallest-adjustment;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  visibility: hidden;
  width: $smallest-adjustment;
}
