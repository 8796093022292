@import 'variables';
@import 'lib-index';

// styles for terminus ui selector, which is being used in filter
@mixin filter-selector($textColor) {
  .ts-form-field {
    color: $textColor;

    .ts-form-field__wrapper {
      margin: 0;
      padding-bottom: 0;
    }

    .ts-form-field__container {
      padding: 0;

      .ts-form-field__outline {
        display: none;
      }

      .ts-form-field__infix {
        border: 0;
        padding: 0;
        width: unset;
      }
    }
  }

  .ts-select-value {
    max-width: unset;
    overflow: unset;
    width: auto;
  }
}

// styles for terminus ui date range, which is being used in filter
@mixin filter-date-range($textColor) {
  align-items: center;

  .c-date-range__separator {
    display: inherit;
    margin: 0;
  }

  .ts-input--datepicker {
    width: 150px;
  }

  .ts-form-field {
    .ts-form-field__container {
      align-items: center;
      margin-top: 0;

      .ts-form-field__infix {
        border-top: 0;
        color: $textColor;
        line-height: unset;
        padding: 0;

        .ts-form-field__label-wrapper {
          display: none;
        }
      }
    }

    .ts-form-field__outline {
      display: none;
    }

    .ts-form-field__suffix {
      padding: 0;
      top: 0;
    }

    .ts-form-field__wrapper {
      margin: 0;
      padding-bottom: 0;
    }
  }
}

/* custom ts icon button for utilities sidenav */
@mixin sidenav-icon-button($buttonWidth, $buttonHeight, $fontSize, $hoverBorderColor) {
  height: $buttonHeight;
  width: $buttonWidth;

  .c-icon-button {
    height: $buttonHeight;
    width: $buttonWidth;
    font-size: $fontSize;
    padding: 2px;
    margin: 0;
    border: 1px solid transparent;
    border-radius: 2px;
    @include transition();

    &:hover {
      border-color: $hoverBorderColor;
    }
  }
}

/* @include transition(all,2s,ease-out); */
@mixin transition($what: all, $time: 0.2s, $how: ease-in-out) {
  -webkit-transition: $what $time $how;
  -moz-transition: $what $time $how;
  -ms-transition: $what $time $how;
  -o-transition: $what $time $how;
  transition: $what $time $how;
}

@mixin text-overflow($type: ellipsis) {
  text-overflow: $type;
  white-space: nowrap;
  overflow: hidden;
}

@mixin report-filters() {
  display: flex;
  margin-bottom: spacing(large);
}

@mixin table-buttons-panel() {
  display: flex;
  justify-content: flex-end;
  margin-bottom: spacing();
}

// styles for page with dashboard's layout
@mixin new-layout-with-sidebar() {
  display: flex;
  min-height: 100%;

  .sidebar {
    width: $dashboard-sidebar-width;
    margin: -24px 0 -24px -24px;
    padding: spacing(large);
    background-color: $grey10;
  }

  .content {
    width: calc(100% - #{$dashboard-sidebar-width});
    position: relative;
    margin-top: -24px;
    padding: spacing(large) 0 0 spacing(large);
    flex: 1 1 auto;

    // TODO: remove h3 and .ts-button after migration to portal directives
    h3 {
      position: absolute;
      top: -25px;
      left: spacing(large);
      font-size: 20px;
      font-weight: 500;
      color: $grey90;
      margin: 0;
    }

    .ts-button {
      position: absolute;
      top: -30px;
      right: 0;
    }
  }
}

@mixin custom-ts-menu($selector: '.menu-item', $minWidth: 192px) {
  #{$selector} {
    cursor: pointer;
    min-width: $minWidth;
    user-select: none;

    i {
      color: $grey60;
    }

    &:first-of-type {
      margin-top: spacing(small, 1);
    }

    &:last-of-type {
      margin-bottom: spacing(small, 1);
    }

    &.justify {
      display: flex;
      justify-content: space-between;
    }

    &.disabled {
      cursor: not-allowed;
      color: $grey60;
    }
  }
}

@mixin button-as-link() {
  background: none;
  border: none;
  cursor: pointer;
  color: $blue70;
  font-size: 14px;
  padding: 0;
  @include transition();

  &:hover {
    text-decoration: underline;
  }

  &:disabled {
    cursor: not-allowed;

    &:hover {
      text-decoration: none;
    }
  }
}

@mixin host-container-styles() {
  @include take-space;
  display: inline-block;
  height: 100%;
  width: 100%;
}

@mixin host-component-styles() {
  display: inline-block;
  min-height: 100%;
  width: 100%;
}

@mixin border-less-btn() {
  ::ng-deep {
    .c-button {
      background-color: transparent;
      border: 0;
    }
  }
}

@mixin clear-vertical-align {
  margin-bottom: 0;
  margin-top: 0;
}

@mixin draggable-menu {
  ::ng-deep {
    .c-checkbox__label {
      display: flex;
      padding-right: 10px;
    }

    .cdk-drag-handle.cdk-drag-handle {
      right: -5px;
      top: 3px;
    }
  }
}

@mixin inline-menu($color: var(--engage-color-dark-blue-label)) {
  ::ng-deep {
    .c-menu__caret {
      color: var(--ts-color-base-palette7-600);
    }

    .ts-button {
      margin: 0;
    }

    .ts-button .c-button {
      background-color: transparent;
      border: none;
      color: $color;
      line-height: normal;
      padding: 0;
    }

    .ts-button .c-button:not([disabled]):hover {
      background-color: transparent;
    }

    .c-menu .c-menu__trigger .c-button .c-menu__caret {
      top: 0;
      width: 10px;
    }
  }
}

@mixin expandable-button($right: 0, $top: 0) {
  position: absolute;
  right: $right;
  top: $top;
  transform: translateY(spacing(small));
  transition: right 100ms ease-in;
  z-index: z(menu-trigger);

  .button-content {
    overflow: hidden;
    transition: .4s width;
    white-space: nowrap;
    width: 13px;
  }

  .button-icon {
    margin-right: spacing(small, 2);
    transition: .4s transform;
  }

  &:hover {
    .button-content {
      width: 205px;
    }

    .button-icon {
      transform: rotate(720deg);
    }
  }

  ::ng-deep {
    .c-button {
      box-shadow: 0 1px 1px rgba(0, 0, 0, .125);
      height: 35px;
      padding: 0 10px;
    }

    button {
      border-radius: 1.5em;
    }
  }
}

@mixin readonly-section-state() {
  display: flex;
  flex-direction: row;
  margin-bottom: spacing(large, 0);

  &:last-child {
    margin-bottom: 0;
  }

  .label {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    color: var(--ts-color-base-palette7-600);
  }

  .value {
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    color: var(--ts-color-utility-700);
  }

  .link {
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    color: var(--ts-color-base-palette1-500);
  }
}
