/**
 * A mixin to correctly nest placeholder styles
 *
 * @example
 *  @include input-placeholder {
 *    color: red;
 *  }
 */
@mixin input-placeholder {
  &::placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

