@import 'lib-index';
@import 'variables';

/* Styles for modal dialog */
/* Use this classes to create modal dialog component (header, body, footer)*/

$modal-padding: spacing(large);

.hub-modal-dialog {
  overflow: auto;

  .mat-dialog-container {
    padding: 0;
  }
}

.hub-modal-header {
  padding: $modal-padding;
  border-bottom: 1px solid $grey20;
}

.hub-modal-title {
  margin: 0;
  font-weight: 500;
}

.hub-modal-body {
  padding: $modal-padding;
}

.hub-modal-footer {
  display: flex;
  padding: $modal-padding;
  border-top: 1px solid $grey20;
}

.hub-modal-footer-between {
  justify-content: space-between;
}

.hub-modal-footer-end {
  justify-content: flex-end;
}

/* Style for file-upload dialog */
.fu-modal-dialog {
  .mat-dialog-container {
    padding: 24px 24px 8px 24px;
    border-radius: 16px;
    min-width: 700px;
    min-height: 450px;

    .completed-progress-bar {
      width: 85%;
      .mat-progress-bar-fill {
        &::after {
          background-color: var(--ts-color-base-palette2-300); // green when completed
        }
      }
    }
  }
}
