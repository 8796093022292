@import '../color';

$defaultColor: #{color(pure)};

/**
 * Styles to make scrollbars always visible on webkit browsers
 *
 * @param color - The color for the border and background (cannot be transparent)
 */
@mixin visible-scrollbars($color: $defaultColor) {
  $webkit-default-radius: 8px;
  $webkit-default-size: 11px;

  &::-webkit-scrollbar {
    -webkit-appearance: none;

    &:vertical {
      width: $webkit-default-size;
    }

    &:horizontal {
      height: $webkit-default-size;
    }
  }

  &::-webkit-scrollbar-thumb {
    $webkit-background-color: rgba(0, 0, 0, .5);
    background-color: $webkit-background-color;
    border: 2px solid $color;
    border-radius: $webkit-default-radius;
  }

  &::-webkit-scrollbar-track {
    background-color: $color;
    border-radius: $webkit-default-radius;
  }

  &::-webkit-scrollbar-corner {
    background-color: $color;
  }
}
