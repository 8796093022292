@use 'sass:math';
@import 'pseudo';

/**
 * Control the ratio for responsive elements
 *
 * @param $x
 *  The x ratio. Default: 1
 * @param $y
 *  The y ratio. Default: 1
 * @param $pseudo
 *  Determine if a pseudo element should be used to control the ratio. Default: true
 * @param $image
 *  The image source. Default: null
 * @example
 *  @include responsive-ratio(3, 4);
 *  @include responsive-ratio(20, 9, true, url('/images/landing-hero.jpg'));
 */
@mixin responsive-ratio($x: 1, $y: 1, $pseudo: true, $image: null) {
  $padding: unquote((math.div($y, $x) * 100) + '%');

  @if $x == null or $y == null {
    @error 'Both the x and y ratios must be passed in.';
  }

  @if $pseudo {
    &::before {
      @include pseudo($position: relative);
      padding-top: $padding;
      width: 100%;
    }
  } @else {
    padding-top: $padding;
  }

  @if $image != null {
    background-image: url($image);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
