/**
 * Reset button base styles
 *
 * @example
 *  @include button-base;
 */
@mixin button-base {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: 0;
  text-align: center;

  &:focus {
    outline: 0;
  }
}
