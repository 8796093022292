@import "variables";
/**
 * HUB Loader
 */
$loaderColor: $grey70;
$size: 4px;
$shift: 5px;
$borderRadius: 50%;
.hub-loader {
  position: relative;
  width: $size;
  height: $size;
  border-radius: $borderRadius;
  background-color: $grey70;
  color: $grey70;
  animation: dotFlashing .5s infinite linear alternate;
  animation-delay: .25s;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &::before {
    left: -#{$shift};
    width: $size;
    height: $size;
    border-radius: $borderRadius;
    background-color: $grey70;
    color: $grey70;
    animation: dotFlashing .5s infinite alternate;
    animation-delay: 0s;
  }

  &::after {
    left: $shift;
    width: $size;
    height: $size;
    border-radius: $borderRadius;
    background-color: $grey70;
    color: $grey70;
    animation: dotFlashing .5s infinite alternate;
    animation-delay: .5s;
  }
}

@keyframes dotFlashing {
  0% {
    background-color: $grey70;
  }
  50%,
  100% {
    background-color: $grey10;
  }
}

.loader-container {
  display: flex;
  justify-content: center;
}
