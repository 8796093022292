/**
 * The map of available cursors
 */
$g-cursors: (
  /* Indicates an alias or copy will be made */
  alias,
  /* Let the browser decide */
  auto,
  /* Indicates ability to resize a piece of the dom; often a table column */
  col-resize,
  /* Indicates ability to copy */
  copy,
  /* Reset to the default cursor */
  default,
  /* Indicates help is available */
  help,
  /* Indicates the ability to drag an item */
  move,
  /* Indicates no available interaction */
  not-allowed,
  /* Indicates the ability to move North and South */
  ns-resize,
  /* Indicates interaction */
  pointer,
  /* Indicates text controls */
  text,
);


/**
 * Retrieve a space from the $g-cursors list.
 *
 * The primary purpose of this function is to enforce which cursors can be used.
 *
 * @param $type
 *  The cursor value to use. Default: auto
 * @example
 *  cursor: cursor();
 *  cursor: cursor(pointer);
 */
@function cursor($type: auto) {
  $index: index($g-cursors, $type);

  // Verify the type exists
  @if ($index == null) {
    @error 'Invalid cursor type name: `#{$type}`. Allowed types: "#{$g-cursors}"';
  }

  @return nth($g-cursors, $index);
}


/**
 * Include a custom cursor
 *
 * @param $type
 *  The cursor value to use.
 * @example
 *  @include cursor;
 *  @include cursor(pointer);
 */
@mixin cursor($type) {
  cursor: cursor($type);
}
