@import 'variables';

/* for report filter selector */
.report-filter-selector.mat-select {
    width: auto;
    color: $blue70;

    .mat-select-value {
        max-width: none;
        color: #152c9c;
        font-weight: 500;
    }
}

.mat-drawer-container {
    background-color: $main-content-background;
    color: $grey90;
}

.search-by-property-container {
    .mat-select-trigger {
        height: 23px;
        line-height: 23px;
        border: 1px solid $grey40;
        padding-left: 12px;
        border-top-right-radius: $main-border-radius;
        border-bottom-right-radius: $main-border-radius;
    }
}

/* for notification component (override terminus styles) */
.mat-snack-bar-container {
    &.tsh-notification-panel {
        background: transparent;
        box-shadow: none;
        color: $grey90;
        min-width: 720px;
        max-width: 720px;
    }
}

.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label {
    background-color: $blue70;
}
