@use '@angular/material' as mat;
@import 'custom-properties';

@import 'style-helpers/color';

$ts-default-primary: mat.define-palette($ts-material-primary, 500, 100, 700);
$ts-default-accent:  mat.define-palette($ts-material-accent, 500, 100, 700);
$ts-default-warn:    mat.define-palette($ts-material-warn, 500, 100, 700);
$ts-app-theme: mat.define-light-theme($ts-default-primary, $ts-default-accent, $ts-default-warn);

@include mat.core;
@import 'generated-classes';

// Include our custom theme
@include mat.all-component-themes($ts-app-theme);

.fake-change {
  color: var(--ts-color-base-white);
  content: 'b';
}
