// Primary
$blue90: #002F4D;
$blue80: #00416A;
$blue70: #1934b8;// ds brand color
$blue40: #A5D7F5;
$blue30: #BFEAFF;
$blue20: #D6EFFF;
$blue10: #E5F5FF;

// Neutrals
$grey90: #22272A; // only for text
$grey70: #334155; // only for text
$grey60: #637178; // only for text
$grey50: #A9B3B9;
$grey40: #C4CBCF;
$grey30: #DDE1E3;
$grey20: #E9EBED;
$grey10: #F1F2F4;

// Red
$red70: #912228;
$red60: #D23D46;
$red20: #F8E2E4;
$red10: #FBEFF0;

// Green
$green70: #1F5E24;
$green60: #2D8734;
$green20: #DEEDDF;
$green10: #EFF6EF;

// Teal
$teal20: #D9F0EC;
$teal70: #1C6658;

// Purple
$purple10: #F1F2F8;
$purple20: #E5E7F3;
$purple60: #5863AB;
$purple70: #414981;

// Yellow
$yellow60: #8A6300;
$yellow50: #F5B000;
$yellow20: #F6E9C8;
$yellow10: #F9F1DC;

$orange10: #FAF1ED;
$orange20: #F4E2D9;
$orange60: #AB5A30;
$orange70: #794022;

// White
$white: #FFFFFF; // for font color
$white-bg: #FFFFFF; // for background-color
$main-content-background: #FFFFFF;

$main-border-radius: 4px;
$dashboard-sidebar-width: 240px; // sidebar for new layout

// padding
$table-horizontal-padding: 16px;
$table-vertical-padding: 12px;
$main-padding: 24px;

// Utility
$navigation-bg-color: #0C3745;
$white-bg-color: #FFFFFF;

// Sidenav utilities
$sidenav-utilities-width: 50px;
$sidenav-utilities-width-expanded: 340px;
$sidenav-background-color: #ECECEC;
$sidenav-badge-bg-color: #FF9B05;

// Left sidenav
$left-sidenav-width: 80px;
$left-sidenav-width-expanded: 270px;

// Top bar
$app-topbar-height: 64px;

// Selection
$option-bg-color: #F2F0F7;
$active-option-bg-color: #CEE1EF;

// Topbar
$app-topbar-height: 64px;
$app-topbar-height-app-switcher: 90px;

// Breakpoints
$bp-xs: 600px;
$bp-sm: 960px;
$bp-md: 1280px;
$bp-lg: 1920px;
$bp-xl: 5000px;

// Legacy
$logo-bg-color-legacy: #282828;
$navigation-bg-color-legacy: #3C3C3C;
$blue-legacy: #1790C5;

// font-size
$main-font-size: 14px;
