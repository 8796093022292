@import 'variables';
@import 'lib-index';

.amcharts-tooltip {
  min-width: 220px;

  &.amcharts-tooltip-tile {
    min-width: 170px;
    width: 170px;

    .section {
      margin-bottom: 8px;
    }

    .subtitle {
      white-space: break-spaces;
    }
  }

  hr {
    border: none;
    border-top: 1px solid $grey70;
    width: 100%;
    margin: spacing(small) 0;
  }

  .title {
    font-weight: 500;
    font-size: 13px;
  }

  .subtitle {
    display: inline-block;
    font-size: 13px;
    color: $grey40;
    margin-bottom: 3px;
  }

  .color {
    width: 8px;
    height: 20px;
    margin-right: spacing(small, 1);
    border-radius: 2px;
  }

  .section {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 20px;

    &:not(:last-of-type) {
      margin-bottom: 2px;
    }
  }

  .previous-section {
    color: $grey50;
    font-weight: normal;
    font-size: 13px;
  }

  .text-capitalize {
    text-transform: capitalize;
  }

  .text-uppercase {
    text-transform: uppercase;
  }

  .bold {
    font-weight: 700;
  }

  .white {
    color: white;
  }

  .diff-section {
    margin-left: 5px;
  }
}

.custom-legend-item {
  display: inline-block;
  font-size: 12px;
  color: $grey60;
  max-width: 130px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &:not(:last-of-type) {
    margin-right: 24px;
  }
}

.custom-legend-marker {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 2px;
  margin-right: 6px;
}

