@import '~@terminus/design-tokens/css/design-tokens.css';
@import '~@terminus/design-tokens/css/library-design-tokens.css';
@import 'terminus-ui';

// ******** Shared styles for all applications *****
// base styles
@import 'common';
// custom styles
@import 'custom-terminus-ui';
// custom angular material styles
@import 'custom-angular-material';
// styles for modal dialogs
@import 'modal-dialog';
// styles for charts
@import 'custom-amcharts';
@import 'table-cell-loader';

// ******** Specific styles for data studio *****
@import 'ds-common';
