@import 'variables';
@import 'lib-index';
@import 'mixins';

table.ts-table .ts-cell.ts-table__column--sticky:not(.ts-table__column--sticky-end),
table.ts-table .ts-footer-cell.ts-table__column--sticky:not(.ts-table__column--sticky-end),
table.ts-table .ts-header-cell.ts-table__column--sticky:not(.ts-table__column--sticky-end) {
    border-right: none;
}

table.ts-table .ts-header-cell,
table.ts-table .ts-header-row {
    background-color: $grey10;
}

table.ts-table .ts-cell {
    color: $grey70;
}

table.ts-table .ts-footer-cell,
table.ts-table .ts-footer-cell.ts-table__column--sticky {
    background-color: $grey10;
    color: $grey70;

    &:last-of-type {
        border-bottom-right-radius: 4px;
    }

    &:first-of-type {
        color: $grey60;
        border-bottom-left-radius: 4px;
    }
}

table.ts-table .ts-cell.expanded-cell {
    padding: 0 0 0 spacing();
    border: 0 solid $grey20;
    border-top: none;
    border-radius: $main-border-radius;
    @include transition();

    &.open {
        padding: spacing();
        border-width: 1px;
    }
}

table.ts-table .expanded-row::after {
    display: none;
}

/* for date cohort */
.date-cohort-container {
    .ts-input--datepicker {
        width: 200px;
    }

    .ts-form-field .ts-form-field__wrapper.ts-form-field--with-validation {
        padding-bottom: 0;
        margin-bottom: 0;
    }
}

/* Update terminus ui styles for table buttons panel */
.table-buttons-panel {
    .ts-menu ts-button .c-button {
        line-height: normal;
        padding: 1px 7px;

        .c-menu__trigger-icon {
            top: 0;
        }
    }

    &.button-group {
        .ts-menu ts-button .c-button {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}

/* Report setting panel */

.report-setting-panel {
    .ts-expansion-panel__indicator {
        display: none;
    }
}

/* search input */
.search-by-property-container {
    &.search-by-property {
        .ts-input .ts-form-field .ts-form-field__outline-end {
            border-radius: 0;
        }

        .ts-selection-list .ts-form-field .ts-form-field__outline-start {
            border-radius: 0;
        }

        .ts-input {
            input {
                border-top-right-radius: unset !important;
                border-bottom-right-radius: unset !important;
            }
        }
    }

    .ts-form-field .ts-form-field__wrapper.ts-form-field--with-validation {
        padding-bottom: 0;
    }
}

ts-paginator.ts-paginator {
    display: flex;
    justify-content: flex-end;
}

/* use ts-menu with a style like we have for report setting filter */
.ts-menu-as-selector {
    font-size: 14px;
    margin-top: -2px;
    height: 19px;

    .ts-button {
        margin: 0;
    }

    .ts-button .c-button {
        background-color: transparent;
        border: none;
        padding: 0;
        line-height: 0;
        color: $blue70;
    }

    .ts-button .c-button:not([disabled]):hover {
        background-color: transparent;
    }

    .c-menu .c-menu__trigger .c-button .c-menu__caret {
        top: 0;
        width: 10px;
        color: $grey60;
    }
}

/* use ts-menu in table like configuration menu */
.configuration-menu.ts-menu {
    .ts-button {
        margin: 0;
    }

    .ts-button .c-button {
        height: 24px;
        line-height: 24px;
        padding: 0 spacing(small, 1);
    }

    .c-menu__trigger-icon {
        top: -1px;
        width: 8px;
        margin-left: spacing(small, 2);

        svg {
            color: $grey60;
        }
    }
}

// override styles for dashboard tiles
.ts-radio-group--visual input:checked+label.ts-radio-button__label {
    border-width: 3px;
}

.ts-radio-group--visual ts-radio-button.ts-radio-button {
    box-shadow: none;
}

.segment-type-group.ts-radio-group--visual .ts-radio-button__label, {
  color: $grey70;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 24px 24px 10px;
}

.tile-type-group.ts-radio-group--visual .ts-radio-button__label {
    color: $grey90;
    align-items: flex-start;
    justify-content: flex-start;
}

/* use ts-menu with a style like we have for column edit + csv download button group */
.ts-menu-for-btn-group {
    font-size: 11px;

    .ts-button .c-button {
        height: 24px;
        line-height: 24px;
    }

    .c-button__content {
        vertical-align: middle;
    }

    .c-menu .c-menu__trigger .c-button .c-menu__caret {
        color: $grey60;
        height: unset;
        top: 0;
        width: 7px;
    }
}
