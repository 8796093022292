/**
 * The list of possible breakpoints
 */
$breakpoints: (
  'layout-xs',
  'layout-gt-xs',
  'layout-sm',
  'layout-gt-sm',
  // @deprecated Incorrect name. Please use `layout-md`
  'layout-mat',
  // @deprecated Incorrect name. Please use `layout-gt-md`
  'layout-gt-mat',
  // @deprecated Incorrect name. Please use `layout-md`
  'layout-med',
  'layout-md',
  // @deprecated Incorrect name. Please use `layout-gt-md`
  'layout-gt-med',
  'layout-lg',
  'layout-gt-lg',
  'layout-xl'
);


/**
 * Wrap styles in a media query
 *
 * @param $point
 *  The desired breakpoint
 * @example
 *  @include bp(layout-gt-xs) {
 *    width: 30%;
 *  }
 *  @include bp(layout-xs) {
 *    width: 100%;
 *  }
 */
@mixin bp($point) {
  @if index($breakpoints, $point) {
    @if $point == 'layout-xs' {
      @media (max-width: 599px) {
        @content;
      }
    }

    @if $point == 'layout-gt-xs' {
      @media (min-width: 600px) {
        @content;
      }
    }

    @if $point == 'layout-sm' {
      @media (min-width: 600px) and (max-width: 959px) {
        @content;
      }
    }

    @if $point == 'layout-gt-sm' {
      @media (min-width: 960px) {
        @content;
      }
    }

    @if ($point == 'layout-mat') or ($point == 'layout-med') or ($point == 'layout-md') {
      @media (min-width: 960px) and (max-width: 1279px) {
        @content;
      }
    }

    @if ($point == 'layout-gt-mat') or ($point == 'layout-gt-med') or ($point == 'layout-gt-md') {
      @media (min-width: 1280px) {
        @content;
      }
    }

    @if $point == 'layout-lg' {
      @media (min-width: 1280px) and (max-width: 1919px) {
        @content;
      }
    }

    @if $point == 'layout-gt-lg' {
      @media (min-width: 1920px) {
        @content;
      }
    }

    @if $point == 'layout-xl' {
      @media (min-width: 1920px) {
        @content;
      }
    }
  } @else {
    @error 'There is no breakpoint `#{$point}` in this list; choose one of: #{$breakpoints}';
  }
}
